export default {
  widgets: [
    {
      "id": "-aQvk3IBXVi9CGZAYXYx",
      "name": "alex test",
      "themeName": "default",
      "description": "as da sdas d",
      "achievementReference": 1,
      "created": "08/06/2020, 09:07"
    },
    {
      "id": "E9Nc5GoBSIi0zrvP322a",
      "name": "asd",
      "themeName": "betsson-theme-01",
      "description": "",
      "achievementReference": 1,
      "created": "23/05/2019, 11:04"
    },
    {
      "id": "-qQwk3IBXVi9CGZAGHYu",
      "name": "asd 2",
      "themeName": "default",
      "description": "",
      "achievementReference": 1,
      "created": "08/06/2020, 09:08"
    }
  ],
  fields: [
    "select",
    "id",
    "name",
    "themeName",
    "description",
    "achievementReference",
    "created",
    "actions"
  ],
  source:`<script type="text/javascript">
    (function (w, d, s, u, o) {
        w[o] = w[o] ? w[o] : [];
        w[o].push({
            memberId: "",
            apiKey: "62d740be981743de88caedad41f2912d",
            spaceName: "alex",
            widgetId: "-aQvk3IBXVi9CGZAYXYx",
        });
        var a = d.createElement(s),
            m = d.getElementsByTagName(s)[0];
        a.async = 1;
        a.src = u;
        m.parentNode.insertBefore(a, m);
    })(window, document, "script", "https://gateway-staging.ziqni.com/assets/javascripts/achievements-widget.js", "_clAchievementOptions");
</script>
<div class="zq-achievement-widget"></div>`
}