<template>
  <div class="preview-achievement-widget">
    <!--  HEADER  -->
    <div class="zq-page-title-wrapper d-flex">
      <h3 class="zq-page-title">{{ texts.widget.preview.title }}</h3>
      <IconWithTooltip class="zq--header-tooltip" :text="descriptions.pageTitle"/>
    </div>
    <div class="content">
      <!--    ACTIONS  -->
      <ActionsForPreview
        :loading="loading"
        @edit="editWidget"
        @delete="openModal"
      />
      <!--  Overview  -->
      <CCard class="zq--wizard-card">
        <CCardHeader @click="basicIsCollapsed = !basicIsCollapsed">
          <div class="d-flex justify-content-between">
            <strong class="title">{{ texts.widget.preview.titleSub }}</strong>
            <CLink class="card-header-action btn-minimize">
              <ClCardArrow :is-collapsed="basicIsCollapsed" />
            </CLink>
          </div>

        </CCardHeader>
        <CCollapse :show="basicIsCollapsed" :duration="400">
          <CCardBody>
            <!--         THEME  NAME     -->
            <ClCardRow :label="texts.widget.preview.themeLabel" :content="data.themeName" />
            <!--        Created     -->
            <ClCardRow :label="texts.widget.preview.createdLabel" :content="data.created" />
            <!--        ID     -->
            <ClCardRow :label="texts.widget.preview.idLabel" :content="data.id" />
            <!--         Name       -->
            <ClCardRow :label="texts.widget.preview.nameLabel" :content="data.name" />
            <!--        Description       -->
            <ClCardRow :label="texts.widget.preview.descriptionLabel" :content="data.description" />
            <!--    Preview CODE    -->
            <CRow>
              <CCol col="12">
                <CCard class="zq--wizard-card preview-widget-code--card">
                  <CCardBody>
                    <PreviewWidgetCode :data="previewCodeData" />
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>
            <!--            <CRow>-->
            <!--              <CCol col="12">-->
            <!--                <pre class="pre-editor">-->
            <!--                  <codemirror-->
            <!--                      class="preview-achievement-widget&#45;&#45;code"-->
            <!--                      ref="sourceCode"-->
            <!--                      :options="editorOptions"-->
            <!--                      :value="source"-->
            <!--                  />-->
            <!--                </pre>-->
            <!--              </CCol>-->
            <!--            </CRow>-->
          </CCardBody>
        </CCollapse>
      </CCard>
    </div>
    <!-- MODAL   -->
    <Modal
      :modalShow="deleteModal"
      :messageGeneral="texts.widget.deleteMessage"
      :title="texts.widget.deleteTitle"
      @doFunction="deleteWidget"
      v-on:toggle-modal="closeModal"
    />
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import ActionsForPreview from "@/shared/components/ActionsForPreview";
import Modal from '@/shared/UI/Modal';
import routerBreadcrumbs from "@/router/breadcrumb/routerBreadcrumbs";
import ClCardRow from "@/shared/UI/ClCardRow";
import ClCardArrow from "@/shared/UI/ClCardArrow";

import IconWithTooltip from '@/shared/UI/IconWithTooltip';
import {achievements} from "@/config/descriptions/achievements"

// MOCK DATA
import mockData from '@/mock/achievementsWidgets';
import PreviewWidgetCode from "@/shared/UI/PreviewWidgetCode";
import {achievementsTexts} from "@/config/pageTexts/achievements.json";

export default {
  components: {
    ActionsForPreview,
    ClCardRow,
    ClCardArrow,
    Modal,
    PreviewWidgetCode,
    IconWithTooltip
  },
  data() {
    return {
      descriptions: {
        ...achievements.widgets.preview
      },
      texts: {
        ...achievementsTexts
      },
      // MOCK
      loading: false,
      data: {},
      source: mockData.source,
      //
      previewCodeData: {
        memberId: '',
        apiKey: '?????????????',
        spaceName: 'alex',
        widgetId: ''
      },
      basicIsCollapsed: true,
      deleteModal: false
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions('achievements', ['handleSetActiveTab']),
    initialize() {
      //TODO: MOCK DATA
      const item = this.$router.currentRoute.params.item;
      this.data = {...this.data, ...item};
      this.previewCodeData.widgetId = item.id;
      const name = item ? item.name : 'name';
      //
      routerBreadcrumbs(this.$router.currentRoute, {
        name
      });
      // SET ACTIVE TAB
      this.handleSetActiveTab(1);
    },
    deleteWidget() {
      this.deleteModal = false;
    },
    editWidget() {
      //TODO: remove 'item' from params(this is mock data)
      this.$router.push({
        name: 'EditWidget',
        params: {
          id: this.data.id,
          name: this.data.name,
          item: this.data
        }
      });
    },
    // MODAL
    openModal() {
      this.deleteModal = true
    },
    closeModal() {
      this.deleteModal = false;
    }
  }
}
</script>

<style lang="scss">
.preview-achievement-widget {
  height: 100%;
  flex: 2;

  .content {
    height: calc(100% - 50px);
    background: var(--zq-sub-bg);
    border-top: 1px solid var(--zq-content-border-color);

    .zq--wizard-card.preview-widget-code--card {
      .card-body {
        border: none;
      }
    }

  }
}
</style>